document.addEventListener('DOMContentLoaded', function () {
    const htmlElement = document.querySelector('html');

    document.addEventListener('keyup', function (e) {
        htmlElement.setAttribute('data-input', 'keyboard');
    });

    document.addEventListener('mousedown', function (e) {
        htmlElement.setAttribute('data-input', 'mouse');
    });
});